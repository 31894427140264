/* eslint-disable max-classes-per-file */
/* JavaScript for DonationForm */
import app from "../../ps1_app";
import Component from "../component";
import { Input } from "./input";

export class Screen extends Component {
  get defaultState() {
    return {
      revealed: this.props.element.dataset.revealed === "true",
      expanded: this.props.element.dataset.expanded === "true",
      touched: this.props.element.dataset.touched === "true",
      errors: [],
    };
  }

  get donationForm() {
    return this.props.donationForm;
  }

  constructor(...props) {
    super(...props);

    this.inputs = [...this.elements.inputs].map(
      (element) => new Input({ element, donationForm: this.props.donationForm })
    );
    this.inputsByName = this.inputs.reduce((acc, input) => {
      acc[input.name] = input;
      input.alternativeNames.forEach((alternativeName) => {
        acc[alternativeName] = input;
      });
      return acc;
    }, {});
  }

  setUpElements() {
    super.setUpElements();
    this.elements.root = this.props.element;
    this.elements.editButton = this.props.element.querySelector(
      ".js-donation-form__edit-button"
    );
    this.elements.inputs = [
      ...this.props.element.querySelectorAll(".js-donation-form__input"),
    ];
    this.elements.cta = this.props.element.querySelector(
      ".js-donation-form__cta"
    );
    this.elements.errors = this.props.element.querySelector(
      ".js-donation-form__errors"
    );
  }

  setUpEvents() {
    super.setUpEvents();

    this.elements.editButton.addEventListener(
      "click",
      this.handleEditClick.bind(this)
    );
    this.elements.cta.addEventListener("click", this.handleCtaClick.bind(this));
  }

  expand() {
    this.update({ expanded: true, touched: true });
    this.focus("firstInput");
  }

  collapse() {
    this.update({ expanded: false });
  }

  toggle() {
    if (this.state.expanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  isValid() {
    return this.inputs.filter((input) => !input.isValid()).length === 0;
  }

  updateErrors() {
    const errorMessages = this.inputs
      .map((input) => input.errorMessage)
      .filter(Boolean);
    const dedupedErrorMessages = [...new Set(errorMessages)];
    this.update({
      errors: dedupedErrorMessages,
    });
    if (Boolean(this.state.errors.length)) {
      this.focus("firstInvalidInput");
    }
  }

  get firstNonHiddenInput() {
    return this.inputs.find((input) => input.type != "hidden")?.focusTarget;
  }

  get firstInvalidInput() {
    return this.inputs.find((input) => !input.isValid())?.focusTarget;
  }

  focus(target = "firstInput") {
    let focusTarget;
    switch (target) {
      case "firstInvalidInput":
        focusTarget = this.firstInvalidInput;
        break;
      case "firstInput":
      default:
        focusTarget = this.firstNonHiddenInput;
    }

    // We don't want to auto-focus a select tag on mobile because it will open a full screen dialog before the user has a chance to see what's going on
    const isSelectInputOnMobile =
      app.isMobile() && focusTarget.tagName === "SELECT";
    if (focusTarget && !isSelectInputOnMobile) {
      focusTarget.focus();
    }
  }

  handleEditClick(event) {
    event.preventDefault();

    this.trigger("edit", { index: this.props.index });
  }

  handleCtaClick(event) {
    if (event.target.dataset.action === "next") {
      event.preventDefault();
      this.trigger("next", { index: this.props.index });
      return;
    }

    this.trigger("submit", { index: this.props.index });
  }

  render(update) {
    if (update.hasOwnProperty("expanded")) {
      this.elements.root.dataset.expanded = update.expanded;
      this.elements.root.setAttribute("aria-expanded", update.expanded);
      this.elements.editButton.setAttribute("aria-hidden", !update.expanded);
    }

    if (update.hasOwnProperty("revealed")) {
      this.elements.root.dataset.revealed = update.revealed;
    }

    if (update.hasOwnProperty("touched")) {
      this.elements.root.dataset.touched = update.touched;
    }

    if (update.hasOwnProperty("errors")) {
      this.elements.errors.innerHTML = update.errors.join("<br/>");
    }
  }
}