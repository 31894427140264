import * as util from "../../utilities";
import Component from "../component";

export class Input extends Component {
  get defaultState() {
    return {
      // TODO: get validity from Adyen input
      valid: null,
      errors: [],
      touched: false,
      adyenValidity: false,
      adyenFocus: false,
      temporarilyHidden:
        this.elements.root.dataset.temporarilyHidden === "true",
    };
  }

  get errorMessage() {
    if (this.state.valid) {
      return;
    }

    const { root, input } = this.elements;
    if (input && input.validity.valueMissing) {
      return `${this.label} is required`;
    } else if (
      root.dataset.requiredIfParentChecked &&
      this.parentInput &&
      this.parentInput.hasValue() &&
      !this.hasValue()
    ) {
      return `${this.label} is required`;
    } else if (
      root.dataset.mutuallyExclusiveWith &&
      root.dataset.mutuallyExclusiveErrorMessage
    ) {
      return root.dataset.mutuallyExclusiveErrorMessage;
    }
    return `${this.label} is invalid`;
  }

  get iframe() {
    this.elements.iframe ||= this.elements.root.querySelector("iframe");

    return this.elements.iframe;
  }

  get donationForm() {
    return this.props.donationForm;
  }

  get focusTarget() {
    switch (this.type) {
      case "hidden":
        return null;
      case "radio":
        // NOTE: Rails automatically inserts a hidden input when you use `collection_radio_buttons`
        return (
          this.elements.input.querySelector("input:checked") ||
          this.elements.input.querySelector("input:not([type='hidden'])")
        );
      case "adyen":
        return this.iframe;
      default:
        return this.elements.input;
    }
  }

  isValid() {
    const validity = this.checkValidity();
    this.update({ valid: validity });
    return this.state.valid;
  }

  hasValue() {
    const { input } = this.elements;
    switch (this.type) {
      case "checkbox":
        return input.checked;
      case "radio":
        return Boolean(input.querySelector("input:checked"));
      default:
        return Boolean(input.value);
    }
  }

  getValue() {
    const { input } = this.elements;
    switch (this.type) {
      case "checkbox":
        return input.checked;
      case "radio":
        const checkedInput = input.querySelector("input:checked");
        return checkedInput ? checkedInput.value : null;
      case "select":
        return input.value;
      default:
        return input.value;
    }
  }

  setValue(value) {
    const { input } = this.elements;
    if (!this.screenHaveBeenRevealed) {
      this.trigger("reveal", { revealedState: true });
      this.screenHaveBeenRevealed = true;
    }

    switch (this.type) {
      case "checkbox":
        input.checked = Boolean(value);
        break;
      case "radio":
        const radioButton = input.querySelector(`[value='${value}']`);
        if (radioButton) {
          radioButton.checked = true;
        }
        break;
      case "select":
        const selectOption = input.querySelector(`[value='${value}']`);
        if (selectOption) {
          selectOption.selected = true;
        }
        break;
      default:
        input.value = value;
        break;
    }
  }

  unsetValue() {
    const { input } = this.elements;
    switch (this.type) {
      case "radio":
        input.querySelectorAll("input").forEach((input) => {
          input.checked = false;
        });
        break;
      case "select":
        input.querySelectorAll("option")[0].selected = true;
        break;
      default:
        input.value = "";
        break;
    }
  }

  adyenFocus() {
    this.update({ adyenFocus: true });
  }

  adyenBlur() {
    this.update({ adyenFocus: false });
  }

  hide() {
    this.update({
      temporarilyHidden: true,
    });
    this.unsetValue();
  }

  show() {
    this.update({
      temporarilyHidden: false,
    });
  }

  toggleVisibility() {
    if (this.state.temporarilyHidden) {
      this.show();
    } else {
      this.hide();
    }
  }

  constructor(...props) {
    super(...props);

    this.name =
      this.elements.root.dataset.name ||
      this.elements.input?.getAttribute("name") ||
      this.elements.label?.getAttribute("for");
    this.alternativeNames =
      this.elements.root.dataset.alternativeNames?.split(",") || [];
    this.label =
      this.elements.root.dataset.label || this.elements.label?.textContent;
    this.type =
      this.elements.root.dataset.type ||
      this.elements.input?.getAttribute("type") ||
      this.elements.input?.tagName.toLowerCase();

    // Update the touched state on load just in case the user has autocompleted the form
    this.update({ touched: Boolean(this.elements.input?.value) });

    this.formatAsCurrency =
      this.elements.root.dataset.formatAsCurrency === "true";
    if (this.elements.input && this.formatAsCurrency) {
      const currencyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      const formatAsCurrency = (value) => {
        return currencyFormatter.format(value.replace(/[^0-9.]/g, "") || 0);
      };
      if (this.elements.input.value) {
        this.elements.input.value = formatAsCurrency(this.elements.input.value);
      }
      this.elements.input.addEventListener("blur", () => {
        if (!this.elements.input.value) {
          return;
        }
        this.elements.input.value = formatAsCurrency(this.elements.input.value);
      });
    }
  }

  setUpElements() {
    super.setUpElements();

    this.elements.root = this.props.element;
    this.elements.label = this.props.element.querySelector("label");
    this.elements.input = this.props.element.querySelector(
      "fieldset, input, select, textarea"
    );
    this.elements.badge = this.props.element.querySelector(
      ".js-donation-form__error-badge"
    );
  }

  setUpEvents() {
    super.setUpEvents();

    const { root, input, label } = this.elements;

    if (input) {
      const handler = this.handleInput.bind(this);
      input.addEventListener("input", handler);
      input.addEventListener("change", handler);
    }
    if (label) {
      label.addEventListener("click", () => {
        this.focusTarget?.focus();
      });
    }

    if (root.dataset.updateStates) {
      const listAvailableStates = () => {
        const countryCode = input.value;
        const stateSelect = document.querySelector(`[name="donation[state]"]`);
        util
          .fetchPageMarkup(`/donate/states-dropdown/${countryCode}`)
          .then((data) => {
            const fragment = document
              .createRange()
              .createContextualFragment(data);
            stateSelect.innerHTML = fragment.querySelector("select").innerHTML;
          });
      };
      if (input.value != "US") {
        listAvailableStates();
      }
      input.addEventListener("change", (e) => {
        listAvailableStates();
      });
    }
  }

  get parentInput() {
    const parentInputElement = this.elements.root.parentNode.closest(
      ".js-donation-form__input"
    );
    if (!parentInputElement) {
      return null;
    }

    return this.donationForm.inputs.find(
      (input) => input.elements.root === parentInputElement
    );
  }

  checkValidity() {
    // Some inputs, such as the captcha, can only be validated server-side.
    if (this.elements.root.dataset.checkValidityServerSide === "true") {
      return true;
    }

    if (this.type === "adyen") {
      return this.state.adyenValidity;
    }

    const { root, input } = this.elements;

    // First check normal validity
    let isValid = input.validity.valid;

    // Special case: radio groups need to delegate the validity from the `fieldset` to the `input[type="radio"]`
    if (this.type === "radio") {
      isValid = this.focusTarget.validity.valid;
    }

    // Then check for custom validity rules.

    // If this input is required when its parent is checked, check that.
    // NOTE: this will only work with string values, not numbers nor booleans.
    if (
      root.dataset.requiredIfParentValueIs &&
      this.parentInput &&
      this.parentInput.getValue() === root.dataset.requiredIfParentValueIs &&
      !this.hasValue()
    ) {
      isValid = false;
    }

    // If this input is required unless an alternative input has a value, check that.
    if (root.dataset.requiredUnlessAlternative) {
      const alternativeInputWithValue = this.donationForm.inputs.find(
        (altInput) =>
          altInput.name === root.dataset.requiredUnlessAlternative &&
          altInput !== this &&
          altInput.hasValue()
      );
      if (alternativeInputWithValue) {
        isValid = true;
      } else if (!this.hasValue()) {
        isValid = false;
      }
    }

    // If this input is required unless an alternative input has a value, check that.
    // Note we don't simply use a number input + min="1" because we want to format it as currency.
    if (isValid && root.dataset.requireNonZeroValue) {
      if (root.dataset.allowNullValue && input.value === "") {
        isValid = true;
      } else {
        const valueAsFloat = parseFloat(
          input.value.replace(/[^0-9.]/g, "") || 0
        );
        isValid = valueAsFloat > 0;
      }
    }

    if (isValid && root.dataset.requireNonBlankValue) {
      if (root.dataset.allowNullValue && input.value === "") {
        isValid = true;
      } else if (this.getValue() === "") {
        isValid = false;
      }
    }

    return isValid;
  }

  handleInput(e) {
    const { root, input } = this.elements;
    const hasValue = this.hasValue();
    const value = this.getValue();

    this.update({
      valid: this.checkValidity(),
      touched: true,
    });

    // Allow inputs to add additional hidden values to the form.
    const setAdditionalKey =
      root.dataset.setAdditionalKey || e.target.dataset.setAdditionalKey;
    const setAdditionalValue =
      root.dataset.setAdditionalValue || e.target.dataset.setAdditionalValue;
    const setAdditionalValueWhen =
      root.dataset.setAdditionalValueWhen ||
      e.target.dataset.setAdditionalValueWhen;
    if (setAdditionalKey && setAdditionalValue) {
      const additionalDonationInput =
        this.donationForm.inputsByName[setAdditionalKey];
      if (setAdditionalValueWhen) {
        if (value === setAdditionalValueWhen) {
          additionalDonationInput?.setValue(setAdditionalValue);
        }
      } else {
        additionalDonationInput?.setValue(setAdditionalValue);
      }
    }

    const unsetAdditionalKey =
      root.dataset.unsetAdditionalKey || e.target.dataset.unsetAdditionalKey;
    const unsetAdditionalKeyWhen =
      root.dataset.unsetAdditionalKeyWhen ||
      e.target.dataset.unsetAdditionalKeyWhen;
    if (unsetAdditionalKey) {
      const additionalDonationInput =
        this.donationForm.inputsByName[unsetAdditionalKey];
      if (unsetAdditionalKeyWhen) {
        if (value === unsetAdditionalKeyWhen) {
          additionalDonationInput?.unsetValue();
        }
      } else {
        additionalDonationInput?.unsetValue();
      }
    }

    if (
      root.dataset.ifPresentSetParentValueTo &&
      hasValue &&
      this.parentInput
    ) {
      this.parentInput.setValue(root.dataset.ifPresentSetParentValueTo);
    }

    if (root.dataset.mutuallyExclusiveWith && hasValue) {
      const mutuallyExclusiveInputNames =
        root.dataset.mutuallyExclusiveWith.split(",");
      const inputsToUnset = this.donationForm.inputs.filter(
        (i) => mutuallyExclusiveInputNames.includes(i.name) && i !== this
      );
      inputsToUnset.forEach((input) => {
        input.unsetValue();
      });
    }

    const toggleHiddenField =
      root.dataset.toggleHiddenField || e.target.dataset.toggleHiddenField;
    let toggleHiddenFieldWhen =
      root.dataset.toggleHiddenFieldWhen ||
      e.target.dataset.toggleHiddenFieldWhen;
    if (toggleHiddenField) {
      toggleHiddenFieldWhen = toggleHiddenFieldWhen.split(",");
      const hiddenField = this.donationForm.inputsByName[toggleHiddenField];
      if (toggleHiddenFieldWhen) {
        if (toggleHiddenFieldWhen.includes(value)) {
          hiddenField?.show();
        } else {
          hiddenField?.hide();
        }
      } else if (hasValue) {
        hiddenField?.show();
      } else {
        hiddenField?.hide();
      }
    }
  }

  render(update) {
    if (update.hasOwnProperty("valid")) {
      this.elements.root.dataset.valid = update.valid;
      this.elements.badge?.setAttribute("aria-label", this.errorMessage || "");
    }

    if (update.hasOwnProperty("touched")) {
      this.elements.root.dataset.touched = update.touched;
    }

    if (update.hasOwnProperty("adyenFocus")) {
      this.elements.root.dataset.adyenFocus = update.adyenFocus;
    }

    if (update.hasOwnProperty("temporarilyHidden")) {
      this.elements.root.dataset.temporarilyHidden = update.temporarilyHidden;
    }
  }

  markAsTouched() {
    this.update({
      touched: true,
    });
  }

  setAdyenValidity(valid) {
    this.update({
      adyenValidity: valid,
    });
  }
}
