/* JavaScript for Trays */
import focusLock from "../focus-lock";
import { SearchActions } from "./search_actions";
import { ShareActions } from "./share_actions";
import app from "../../ps1_app";
import * as animations from "../../utilities/animations";
import * as headerUtilities from "./utilities";
import { getFirstKeyboardFocusableElement } from "../../utilities";

export const trayController = {
  current: null,
};

export class TrayController {
  constructor(element, root, header) {
    // ELEMENTS
    this.elements = {};
    this.elements.root = root;
    this.elements.element = element;
    this.elements.shareTray = root.querySelector(".js-tray-share");
    this.elements.searchTrayMobile = root.querySelector(
      ".js-tray-search-mobile"
    );
    this.elements.searchTrays = root.querySelectorAll(".js-tray-search");

    // KEEP TRACK OF THIS INSTANCE GLOBALLY
    trayController.current = this;

    // STATE
    this.state = {
      open: false,
      activeTray: null,
      activeClass: null,
      activeButton: null,
      focusLock: null,
      isMobile: app.isMobile(),
    };

    // PARENT COMPONENTS
    this.header = header;

    // SUBCOMPONENTS
    this.searchTrays = [...this.elements.searchTrays].map(
      (searchTray) => new SearchActions(searchTray)
    );
    this.searchMobileTray = new SearchActions(this.elements.searchTrayMobile);
    this.shareActions = new ShareActions(this.elements.shareTray);
    // TODO: we should put newsletter sign up form here, i think. Otherwise search & share should be initialized in the main entrypoint.

    // EVENTS
    this.events = {};

    document.addEventListener("click", (e) => {
      const button = e.target.closest(
        ".js-tray-button, .js-tray-button--collapser"
      );
      if (!button) {
        return;
      }

      if (button.matches(".js-tray-button")) {
        const { tray } = button.dataset;
        this.activeButton = button;
        if (this.state.activeTray === tray) {
          this.close();
        } else {
          this.open(tray);
        }
      } else {
        this.close();
      }
    });
  }

  open(tray) {
    if (this.state.activeTray) {
      this.close();
    }

    this.state.activeTray = tray;
    this.state.open = true;
    this.activeButton.setAttribute("aria-expanded", true);
    if (app.isMobile()) {
      this.openMobileTray(tray);
    } else {
      this.openDesktopTray(tray);
    }
  }

  close() {
    // prevent calling function on replicated trays
    if (!this.state.activeTray) {
      return;
    }
    this.closeTray(this.state.activeTray);
    this.state.open = false;
    this.state.activeTray = null;
    this.activeButton.setAttribute("aria-expanded", false);
    this.activeButton.setAttribute("tabindex", "0");
    this.activeButton.focus();
    if (this.state.unlockFocus) {
      this.state.unlockFocus();
      this.state.unlockFocus = null;
    }
  }

  focusInput(input) {
    input.setAttribute("tabindex", "0");
    setTimeout(() => {
      input.focus();
    }, "300");
  }

  triggerNativeSharing = async () => {
    const pageTitle = document.title || "MoMA PS1";
    const currentPath = window.location.href || "https://www.momaps1.org/";
    try {
      const shareData = {
        title: pageTitle,
        url: currentPath,
      };
      await navigator.share(shareData);
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  openMobileTray(tray) {
    if (
      tray === "search" ||
      tray === "newsletter" ||
      tray === "accessibility"
    ) {
      this.elements.root.classList.add(`tray-${tray}-mobile-active`);
      const mobileTray = this.elements.root.querySelector(
        `.js-tray-${tray}-mobile`
      );
      animations.fadeInElement(mobileTray, {
        duration: 300,
      });
      const trayInput = mobileTray.querySelector(".js-tray-input");
      if (trayInput) {
        this.focusInput(trayInput);
      }
    } else if (tray === "share") {
      // If navigator.share is not available,
      // default to desktop tray
      if (navigator.share) {
        this.triggerNativeSharing();
      } else {
        this.openDesktopTray("share");
      }
    }
  }

  getloopTarget = () => {
    if (
      document.activeElement.matches(".js-collapsed-page-header *") ||
      document.activeElement.matches("#application-yield *")
    ) {
      return this.firstFocusableElement;
    }
    return this.trayCloser;
  };

  openDesktopTray(tray) {
    let trayContainer = this.elements.root.querySelector(`.js-tray-${tray}`);
    this.state.activeClass = `.js-tray-${tray}`;
    const options = { display: "block", position: "fixed", top: "0px" };
    if (
      tray === "search" ||
      tray === "newsletter" ||
      tray === "accessibility"
    ) {
      if (this.header.state.expanded || this.header?.menu?.state?.open) {
        app.setTrayOffset({ menuOpen: this.header?.menu?.state?.open });
        options.position = "absolute";
        options.top = `${app.state.trayOffset}px`;
        trayContainer = this.elements.root.querySelector(
          `.tray-${tray}--expanded`
        );
        this.state.activeClass = `.tray-${tray}--expanded`;
        if (this.header?.menu?.state?.open) {
          options.position = "fixed";
        }
      } else {
        trayContainer = this.elements.root.querySelector(
          `.tray-${tray}--collapsed`
        );
        this.state.activeClass = `.tray-${tray}--collapsed`;
      }
      const trayInput = trayContainer.querySelector(".js-tray-input");
      if (trayInput) {
        this.focusInput(trayInput);
      }
    }
    const targetTray = trayContainer.querySelector(".menu-tray");
    this.trayCloser = trayContainer.querySelector(
      ".menu-tray .js-tray-button--collapser"
    );
    this.firstFocusableElement = getFirstKeyboardFocusableElement(targetTray);
    const unlockFocus = focusLock.lock({
      selectors: [`${this.state.activeClass} *`],
      loopTarget: this.getloopTarget,
    });
    this.state.unlockFocus = unlockFocus;
    headerUtilities.showElement(trayContainer, options);
    animations.slideInElement(targetTray, {
      duration: 300,
    });
  }

  closeTray() {
    const tray = this.state.activeTray;
    if (
      app.isMobile() &&
      (tray === "search" || tray === "newsletter" || tray === "accessibility")
    ) {
      this.elements.root.classList.remove(`tray-${tray}-mobile-active`);
      const mobileTray = this.elements.root.querySelector(
        `.js-tray-${tray}-mobile`
      );
      animations.fadeOutElement(mobileTray, {
        duration: 300,
      });
    } else {
      let trayContainer = this.elements.root.querySelector(`.js-tray-${tray}`);
      if (
        tray === "search" ||
        tray === "newsletter" ||
        tray === "accessibility"
      ) {
        if (this.header.state.expanded || this.header.menu?.state?.open) {
          trayContainer = this.elements.root.querySelector(
            `.tray-${tray}--expanded`
          );
        } else {
          trayContainer = this.elements.root.querySelector(
            `.tray-${tray}--collapsed`
          );
        }
      }
      const targetTray = trayContainer.querySelector(".menu-tray");
      animations
        .slideOutElement(targetTray, {
          duration: 300,
          translate: "-112%", // 12% added to accommodate for box shadow
        })
        .then(() => {
          this.displayTimer = setTimeout(() => {
            headerUtilities.hideElement(trayContainer, "none");
          }, 600);
        });
      this.state.unlockFocus();
    }
    if (tray === "share") {
      this.shareActions.reset();
    }
  }
}
