/* eslint-disable max-classes-per-file */
/* JavaScript for DonationForm */
import AdyenCheckout from "@adyen/adyen-web";
import Component from "../component";
import { Screen } from "./screen";

// Export the class itself
export class DonationForm extends Component {
  get currentScreen() {
    return this.screens[this.state.currentScreen];
  }

  get defaultState() {
    return {
      currentScreen: 0,
      result: "pending",
      adyenData: {},
    };
  }

  constructor(...props) {
    super(...props);

    this.screens = [...this.elements.screens].map(
      (element, index) => new Screen({ element, index, donationForm: this })
    );
    this.screens.forEach((screen) => {
      screen.on("edit", ({ index }) => {
        // If the request to edit is for the current screen, do nothing
        if (this.state.currentScreen === index) {
          return;
        }

        // If the request to edit is for the next screen, check if the current screen is valid
        if (index > this.state.currentScreen && !this.currentScreen.isValid()) {
          this.currentScreen.updateErrors();
          return;
        }

        this.goTo(index);
      });

      screen.on("next", this.next.bind(this));
    });
    this.inputs = this.screens.map((s) => s.inputs).flat();
    this.inputsByName = this.screens.reduce((acc, screen) => {
      return {
        ...acc,
        ...screen.inputsByName,
      };
    }, {});
    this.inputs.forEach((input) => {
      input.on("reveal", ({ revealedState }) => {
        if (revealedState) {
          this.revealScreens(revealedState);
        }
      });
      input.checkValidity();
    });

    this.setUpAdyen();

    window.donationForm = this;
  }

  async setUpAdyen() {
    const { paymentMethodsEndpoint, clientKey, environment } =
      this.elements.form.dataset;
    // Get the available payment methods from the server
    const { paymentMethods } = await fetch(paymentMethodsEndpoint).then((res) =>
      res.json()
    );
    const brands =
      paymentMethods.find((method) => method.type === "scheme")?.brands ?? [];

    // Create the Adyen client
    this.checkout = await AdyenCheckout({
      locale: "en_US",
      environment,
      clientKey,
    });

    // Instantiate the Adyen Secured Fields card component
    const { fontSize, fontFamily } = window.getComputedStyle(
      this.elements.typeSample
    );
    this.customCard = this.checkout
      .create("securedfields", {
        // Optional configuration
        type: "card",
        brands,
        styles: {
          base: {
            color: "#315a3a",
            fontSize: fontSize,
            fontSmoothing: "antialiased",
            fontFamily: fontFamily,
          },
          error: {
            color: "#315a3a",
          },
          placeholder: {
            color: "transparent",
          },
          validated: {
            color: "#315a3a",
          },
        },
        // Events
        onChange: ({ data, errors, valid }) => {
          this.update({
            adyenData: data?.paymentMethod || {},
          });

          Object.entries(valid).forEach(([fieldType, fieldIsValid]) => {
            const input = this.findInputByName(fieldType);
            if (!input) {
              return;
            }

            input.setAdyenValidity(fieldIsValid);
          });
        },
        onFieldValid: ({ fieldType, valid }) => {
          const input = this.findInputByName(fieldType);
          if (!input) {
            return;
          }

          input.setAdyenValidity(valid);
        },
        onError: () => {
          this.update({ result: "failure" });
        },
        onFocus: (event) => {
          const { fieldType, focus: isFocused } = event;
          const input = this.findInputByName(fieldType);
          if (!input) {
            return;
          }

          input.markAsTouched();
          if (isFocused) {
            input.adyenFocus();
          } else {
            input.adyenBlur();
          }
        },
        onBinValue: function (bin) {},
        onBinLookup: function (callbackObj) {},
      })
      .mount("#customCard-container");
  }

  revealScreens(revealedState) {
    this.screens.forEach((screen) => {
      screen.update({ revealed: revealedState });
    });
  }

  setUpElements() {
    super.setUpElements();

    this.elements.root = this.props.element;
    this.elements.form = this.props.element.querySelector(
      ".js-donation-form__form"
    );
    this.elements.screens = this.props.element.querySelectorAll(
      ".js-donation-form__screen"
    );
    this.elements.confirmation = this.props.element.querySelector(
      ".js-donation-form__confirmation"
    );
    this.elements.typeSample = this.props.element.querySelector(
      ".js-donation-form__type-sample"
    );
    this.elements.donationAmountNumber = this.props.element.querySelector(
      ".js-donation-form__donation-amount-number"
    );
    this.elements.donationAmountDisplay = this.props.element.querySelector(
      ".js-donation-form__donation-amount-display"
    );
    this.elements.adyenInputs = this.props.element.querySelector(
      ".js-donation-form__adyen-inputs"
    );
  }

  next() {
    if (this.state.currentScreen === this.screens.length - 1) {
      return;
    }

    if (!this.currentScreen.isValid()) {
      this.currentScreen.updateErrors();
      return;
    }

    this.update({ currentScreen: this.state.currentScreen + 1 });
  }

  previous() {
    if (this.state.currentScreen === 0) {
      return;
    }

    this.update({ currentScreen: this.state.currentScreen - 1 });
  }

  goTo(screen) {
    if (screen < 0 || screen >= this.screens.length) {
      return;
    }

    this.update({ currentScreen: screen });
  }

  formIsValid() {
    const invalidScreen = this.screens.find((screen) => !screen.isValid());
    if (invalidScreen) {
      this.goTo(invalidScreen.props.index);
      return false;
    }

    return true;
  }

  findScreenByInputName(name) {
    return this.screens.find((screen) => screen.inputs[name] !== undefined);
  }

  findInputByName(name) {
    return this.inputsByName[name];
  }

  render(update, previousState) {
    if (update.hasOwnProperty("currentScreen")) {
      if (typeof previousState.currentScreen === "number") {
        const previousScreen = this.screens[previousState.currentScreen];
        previousScreen.collapse();
        previousScreen.isValid();
        previousScreen.updateErrors();
      }
      this.screens[update.currentScreen].expand();
    }

    if (update.hasOwnProperty("result")) {
      this.elements.root.setAttribute("data-result", update.result);
    }

    if (update.hasOwnProperty("adyenData")) {
      this.elements.adyenInputs.innerHTML = "";
      Object.entries(this.state.adyenData).forEach(([key, value]) => {
        this.elements.adyenInputs.appendChild(
          Object.assign(document.createElement("input"), {
            type: "hidden",
            name: `adyen[${key}]`,
            value,
          })
        );
      });
    }
  }
}

// Exports an array of all the current instances
export const donationForms = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the DonationForm on any given page
  document.addEventListener("turbo:frame-load", () => {
    console.log("turob load ", document.querySelectorAll(".js-donation-form"));
    donationForms.current = [
      ...document.querySelectorAll(".js-donation-form"),
    ].map((element) => new DonationForm({ element }));
  });
};
