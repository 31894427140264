/* global $ */
// UNCOMMENT FOR DEBOUNCE OR THROTTLE SUPPORT. NOTE: REQUIRES LODASH TO BE INSTALLED
// import debounce from "lodash/debounce";
// import throttle from "lodash/throttle";
import * as util from "./utilities";

export const EVENT_REDUCED_MOTION_SETTING = "setting:reduce-motion-changed";

class App {
  constructor() {
    window.app = this;
    this.rootFontSize = App.getRootFontSize();

    // NOTE: breakpoints in rem units
    // NOTE: MAKE THIS MATCH YOUR SCSS BREAKPOINTS
    this.breakpoints = {
      xl: 100, // 1600px+
      lg: 75, // 1200px – 1599px
      md: 48, // 768px – 1199px
      sm: 36, // 576px – 767px
      xs: 0, // 0px – 575px
    };

    // Initial state
    this.state = {
      breakpoint: this.getBreakpoint(),
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      autoplay: this.autoplay,
      paused: undefined,
      contentHeight: null,
      headerHeight: null,
      menuControlsWidth: null,
      menuHeight: null,
      trayOffset: null,
    };

    // Recognized events
    const config = [
      { parent: document, type: "turbolinks:load" },
      { parent: document, type: "turbolinks:visit" },
      { parent: document, type: "turbolinks:before-visit" },
      { parent: document, type: "turbolinks:before-render" },
      { parent: document, type: "turbolinks:before-cache" },
      { parent: document, type: "DOMContentLoaded" },
      { parent: document, type: "focusin" },
      { parent: document, type: "focusout" },
      { parent: document, type: "mouseenter" },
      { parent: document, type: "mouseleave" },
      { parent: document, type: "click" },
      { parent: document, type: "change" },
      { parent: document, type: "keydown" },
      { parent: document, type: "keyup" },
      { parent: window, type: "scroll", throttle: 100 },
      { parent: window, type: "resize", debounce: 200 },
      { parent: window, type: "breakpoint" },
      { parent: document, type: "pageLoad" },
      { parent: window, type: "popstate" },
      { parent: document, type: "modal:load" },
      { parent: document, type: "modal:backgroundPageLoad" },
      { parent: document, type: "modal:open" },
      { parent: document, type: "modal:close" },
      { parent: document, type: "modal:change" },
      { parent: document, type: "modal:layout" },
      { parent: document, type: "modal:initialize" },
      { parent: document, type: "economy:magic_module_updated" },
      { parent: document, type: "economy:magic_module_order_updated" },
      { parent: document, type: "economy:magic_module_edit" },
      { parent: document, type: "homepage:load" },
      { parent: document, type: "calendar:load" },
      { parent: document, type: "pause" },
      { parent: document, type: "play" },
      { parent: document, type: "toggle-page:load" },
      { parent: document, type: "toggle-pages:toggle-start" },
      { parent: document, type: "toggle-pages:toggle-end" },
      { parent: document, type: EVENT_REDUCED_MOTION_SETTING },
    ];

    // Build standardized events object
    this.events = config.reduce((acc, { type }) => {
      acc[type] = {};
      return acc;
    }, {});

    // Attach a listener for each registered event type,
    // optionally debouncing the handlers.
    config.forEach((eventConfig) => {
      const executeAllHandlers = (originalEvent) => {
        this.trigger(eventConfig.type, originalEvent);
      };

      // UNCOMMENT FOR DEBOUNCE OR THROTTLE SUPPORT. NOTE: REQUIRES LODASH TO BE INSTALLED
      // if (eventConfig.debounce) {
      //   executeAllHandlers = debounce(executeAllHandlers, eventConfig.debounce);
      // } else if (eventConfig.throttle) {
      //   executeAllHandlers = throttle(executeAllHandlers, eventConfig.throttle);
      // }

      eventConfig.parent.addEventListener(eventConfig.type, executeAllHandlers);
    });

    // Setup custom events
    this.addEventListener("resize", {
      name: "breakpoint-checker",
      handler: () => {
        this.state.windowWidth = window.innerWidth;
        this.state.windowHeight = window.innerHeight;
        const previousBreakpoint = this.state.breakpoint;
        const currentBreakpoint = this.getBreakpoint();
        if (currentBreakpoint !== this.state.breakpoint) {
          this.state.breakpoint = currentBreakpoint;
          this.trigger("breakpoint", {
            previous: previousBreakpoint,
            current: currentBreakpoint,
          });
          this.setContentDimensions();
          this.setDynamicHeaderHeight();
          this.setMenuHeight();
          this.setTrayOffset();
        }
      },
    });

    this.addEventListener("turbolinks:load", () => {
      this.trigger("pageLoad", {
        target: document.body,
        originalEvent: "turbolinks:load"
      });
    });

    $(window).on("economy:magic_module_updated", (e) => {
      this.trigger("pageLoad", {
        target: e.target,
        originalEvent: "economy:magic_module_updated"
      });

      this.trigger("economy:magic_module_updated", {
        target: e.target,
      });
    });

    $(window).on("economy:magic_module_order_updated", () => {
      const container = document.getElementById("magic-modules");
      this.trigger("economy:magic_module_order_updated", {
        target: container,
        magicModules: container.querySelectorAll(".mm"),
      });
    });

    $(window).on("economy:init:fields", (e) => {
      this.trigger("economy:magic_module_edit", {
        target: e.target,
      });
    });

    this.addEventListener("modal:load", (e) => {
      this.trigger("pageLoad", {
        target: e.target,
        originalEvent: "modal:load"
      });
    });

    this.addEventListener("homepage:load", (e) => {
      this.trigger("pageLoad", {
        target: e.target,
        originalEvent: "homepage:load"
      });
    });

    this.addEventListener("calendar:load", (e) => {
      this.trigger("pageLoad", {
        target: e.target,
        originalEvent: "calendar:load"
      });
    });

    this.addEventListener("toggle-page:load", (e) => {
      this.trigger("pageLoad", {
        target: e.target,
        originalEvent: "toggle-page:load"
      });
    });

    // Initial renders
    this.addEventListener("DOMContentLoaded", () => {
      this.contentHeightStyleBlock = document.createElement("style");
      this.dynamicHeaderHeightStyleBlock = document.createElement("style");
      document.head.appendChild(this.contentHeightStyleBlock);
      document.head.appendChild(this.dynamicHeaderHeightStyleBlock);
    });

    this.addEventListener("turbolinks:load", () => {
      if (util.isEconomyEditMode()) {
        return;
      }

      this.resetAutoplay();
      this.setElements();
      this.setContentDimensions();
      this.setDynamicHeaderHeight();
      this.setTrayOffset();
    });
  }

  addEventListener(type, e) {
    /* eslint-disable no-console */
    if (!this.events[type]) {
      console.warn(
        `Could not add event listener because "${type}" is not one of the expected events. Expected:`,
        Object.keys(this.events)
      );

      return false;
    }

    const name = e.name || Object.keys(this.events[type]).length;
    const handler = e instanceof Function ? e : e.handler;
    this.events[type][name] = handler;

    return this.removeEventListener.bind(this, type, name);
  }

  removeEventListener(type, data) {
    const name = (typeof data === "object" && data.name) || data;
    if (this.events[type] && this.events[type][name]) {
      delete this.events[type][name];
    }
  }

  trigger(type, originalEvent) {
    if (!this.events[type]) {
      return;
    }

    Object.values(this.events[type]).forEach((handler) => {
      if (handler instanceof Function) {
        handler(originalEvent);
      }
    });
  }

  updateState(update) {
    this.state = Object.assign({}, this.state, update);
  }

  // NOTE: do not call this method directly. Opt instead to use `this.state.breakpoint`
  getBreakpoint() {
    const viewportWidth = window.innerWidth / this.rootFontSize;
    const [breakpoint] = Object.entries(this.breakpoints).find(
      ([_, minViewportSize]) => viewportWidth >= minViewportSize // eslint-disable-line no-unused-vars
    );
    return breakpoint;
  }

  isMobile() {
    return this.state.breakpoint === "xs" || this.state.breakpoint === "sm";
  }

  prefersReducedMotion() {
    const reducedMotionMediaQuery = window.matchMedia(
      "(prefers-reduced-motion: reduce)"
    );
    return reducedMotionMediaQuery.matches;
  }

  set autoplay(value) {
    // This function allows the user to manually adjust whether or not they want
    // things to autoplay on our site.
    // Store the value in state for easy access
    this.state.autoplay = value;
  }

  get autoplay() {
    // Firstly look to see if we have a value stored in state
    if (typeof this.state?.autoplay === "boolean") {
      return this.state.autoplay;
    }

    // If all else fails, fall back on the user's `prefers-reduced-motion` setting
    return !this.prefersReducedMotion();
  }

  resetAutoplay() {
    this.autoplay = !this.prefersReducedMotion();
  }

  pause() {
    this.autoplay = false;
    this.state.paused = true;
    this.trigger("pause");
  }

  play() {
    this.autoplay = true;
    this.state.paused = false;
    this.trigger("play");
  }

  // NOTE: css media queries use rem units, so our js recreation of breakpoint should too.
  // We need the root font size in pixels to convert `window.innerWidth` to rem units.
  // `getComputedStyle` causes DOM reflow, though, so we want to minimize how often we check it.
  static getRootFontSize() {
    return parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  setElements() {
    // Set elements: this needs to be done every turbolinks visit since the element is replaced
    this.elements = {};
    this.elements.header = document.getElementById("site-header");
    this.elements.menuControls =
      this.elements.header.querySelector(".menu-controls");
    this.elements.menu = this.elements.header.querySelector(".js-menu-nav");
    this.elements.expandedHeader = document.querySelector(
      ".js-expanded-page-header"
    );
    this.elements.collapsedHeader = document.querySelector(
      ".js-collapsed-page-header"
    );
    this.elements.modalHeader = document.querySelector(".js-modal-header");
  }

  setMenuHeight() {
    if (this.elements.menu) {
      this.state.menuHeight = this.elements.menu.offsetHeight;
    }
  }

  setContentDimensions() {
    if (!this.contentHeightStyleBlock) {
      return;
    }

    // Accumulate rules that will be added to a style tag
    const cssRules = [];

    // Grab the heights from each of those elements
    if (this.elements.expandedHeader) {
      this.state.expandedHeaderHeight =
        this.elements.expandedHeader.offsetHeight;
      cssRules.push(
        `--expanded-header-height: ${this.state.expandedHeaderHeight}px;`
      );
    }

    if (this.elements.collapsedHeader) {
      this.state.collapsedHeaderHeight =
        this.elements.collapsedHeader.offsetHeight;
      cssRules.push(
        `--collapsed-header-height: ${this.state.collapsedHeaderHeight}px;`
      );
    }

    if (this.elements.modalHeader) {
      this.state.modalHeaderHeight = this.elements.modalHeader.offsetHeight;
      cssRules.push(
        `--modal-header-height: ${this.state.modalHeaderHeight}px;`
      );
    }

    if (this.state.dynamicHeaderHeight) {
      this.state.contentHeight =
        window.innerHeight - this.state.dynamicHeaderHeight;
      cssRules.push(`--content-height: ${this.state.contentHeight}px;`);
    }

    if (this.elements.menuControls) {
      this.state.menuControlsWidth = this.elements.menuControls.offsetWidth;
      cssRules.push(
        `--menu-controls-width: ${this.state.menuControlsWidth}px;`
      );
    }

    this.contentHeightStyleBlock.innerHTML = `:root {
      ${cssRules.join("\n")}
    }`;
  }

  setTrayOffset(options = {}) {
    const { menuOpen = false } = options;
    if (menuOpen) {
      this.setMenuHeight();
      this.state.trayOffset = this.state.menuHeight;
    } else if (!menuOpen) {
      this.setContentDimensions();
      this.state.trayOffset = this.state.expandedHeaderHeight;
    }
  }

  setDynamicHeaderHeight() {
    if (!this.dynamicHeaderHeightStyleBlock) {
      return;
    }

    if (this.elements.modalHeader) {
      this.state.dynamicHeaderHeight = this.state.modalHeaderHeight;
    } else if (this.elements.header) {
      if (this.elements.header.classList.contains("page-header--collapsed")) {
        this.state.dynamicHeaderHeight = this.state.collapsedHeaderHeight;
      } else {
        this.state.dynamicHeaderHeight = this.state.expandedHeaderHeight;
      }
    }

    this.dynamicHeaderHeightStyleBlock.innerHTML = `:root {
      --dynamic-header-height: ${this.state.dynamicHeaderHeight}px;
    }`;
  }
}
export default new App();
