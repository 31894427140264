/* JavaScript for ShareActions */

// Export the class itself
export class SearchActions {
  constructor(element) {
    this.element = element;
    this.searchInput = element.querySelector(".js-search-input");
    this.searchButton = element.querySelector(".js-tray-search__submit");
    this.state = {
      term: "",
    };

    this.searchButton.addEventListener("click", () => {
      this.updateSearchTerm();
      this.search();
    });

    this.searchInput.addEventListener("input", () => {
      const enabledClass = "js-tray-search__submit--enabled";
      const submitEnabled = this.searchButton.classList.contains(enabledClass);
      this.updateSearchTerm();
      if (this.state.term !== "" && !submitEnabled) {
        this.searchButton.classList.add(enabledClass);
      } else if (this.state.term === "" && submitEnabled) {
        this.searchButton.classList.remove(enabledClass);
      }
    });

    this.searchInput.addEventListener("keyup", (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        this.updateSearchTerm();
        this.search();
      }
    });
  }

  updateSearchTerm() {
    this.state.term = this.searchInput.value;
  }

  search() {
    window.location.href = `${window.location.origin}/search?q=${this.state.term}`;
  }
}
